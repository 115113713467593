import { graphql } from 'gatsby';
import React from 'react';
import { GatybyPageProps, IGatsbyQueryResultProps, IGatsbyRemark } from '~/types';
import Overlay from '../comps/overlay';
import './markdown.scss';

const RemarkTemplate = (props: GatybyPageProps<{slug: string}> & IGatsbyQueryResultProps<{md: IGatsbyRemark}>) => {
  const {data} = props
  
  return (
    <Overlay fullHeight>
      <div className="markdown-comp">
        <div dangerouslySetInnerHTML={{__html: data.md.nodes[0].html || ''}}></div>
      </div>
    </Overlay>
  )
}

export default RemarkTemplate

export const query = graphql`
  query($slug: String!){
    md:allMarkdownRemark(filter:{frontmatter:{path: {eq: $slug}}}) {
      nodes {
        html    
        frontmatter {
          title
          path
        }
      }
    }
  }
`